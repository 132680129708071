<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:isEditableRegistry="isEditableRegistry"
			:showDeleteBtn="false"
			:externalFilter="withDocumentFilter"
		></pui-datatable>
		<notificacion-modals
			:modelName="modelName"
		></notificacion-modals>
	</div>
</template>

<script>
import notificacionActions from './NotificacionActions';
import notificacionModals from './NotificacionModals.vue';
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'notificacion-grid',
	components: {
		'notificacion-modals': notificacionModals
	},
	data() {
		return {
			withDocumentFilter: JSON.parse(`{"groupOp":"and","groups":null,"rules":[{"field":"totaldocs","op":"gt","data":"0"}]}`),
			modelName: 'notificacion',
			mixins: [PuiGridDetailMixin],
			actions: notificacionActions.gridactions,
			modelColumnDefs: {
				manual: {
					render: (data) => {
                        if (data === 'S') {
                            return "<i aria-hidden='true' class='v-icon fa fa fa fa-check-circle theme--light green--text' style='font-size: 16px;'></i>";
                        } else {
                            return "<i aria-hidden='true' class='v-icon fa fa fa fa-ban theme--light red--text' style='font-size: 16px;'></i></label>";
                        }
					}
				},
				art15: {
					render: (data) => {
                        if (data === 'S') {
                            return "<i aria-hidden='true' class='v-icon fa fa fa fa-check-circle theme--light green--text' style='font-size: 16px;'></i>";
                        } else {
                            return "<i aria-hidden='true' class='v-icon fa fa fa fa-ban theme--light red--text' style='font-size: 16px;'></i></label>";
                        }
					}
				},
				numdoc: {
					render: (data, type, row) => {
                        if(row.pesonetototalent != null && row.pesonetototalent >= 200000 && row.idtipotranssal !== 4 && row.idtipoman === 1 && (row.idestado !== 4 && row.idestado !== 5 && row.idestado !== 6)) {
							return `<label  style="background-color: #f2aa3d; border-radius: 5px;">${data}</label>`;
						} else if(row.pesonetototalsal != null && row.pesonetototalsal >= 200000 && row.idtipoman === 2 && row.idtipotransent !== 4 && (row.idestado !== 4 && row.idestado !== 5 && row.idestado !== 6))  {
							return `<label  style="background-color: #eb2ff5; border-radius: 5px;">${data}</label>`;
						} else if(row.pesonetototaltran != null && row.pesonetototaltran >= 200000 && (row.idtipotranssal !== 4 && row.idtipotransent !== 4)
						&& (row.idtipoman === 3  || row.idtipoman === 4 || row.idtipoman === 5)
						&& (row.idestado !== 4 && row.idestado !== 5 && row.idestado !== 6)) {
							return `<label  style="background-color: #2cf71e; border-radius: 5px;">${data}</label>`;
						}
						return data;
					}
				}

			}
		}
	},

	methods: {
		isEditableRegistry(registry) {
			return registry && registry.idestado===1;
		}
	},
	mounted() {

	}


}
</script>
